import React, {Component} from 'react';
import {Button, Table} from "antd";
import {openNewTab} from "../../utils";
import PubSub from 'pubsub-js'
import {CustomBtnModal} from "../Header/BtnLine";

class Footer extends Component {


    constructor(props) {
        super(props);

        this.state = {
            sponsorInfo: [],
        }
        //在初始化状态时订阅消息
        this.token = PubSub.subscribe('sponsorInfo', (_, stateObj) => {
                this.setState(stateObj)
            }
        )
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
    }
    render() {

        const columns = [
            {
                title: '网站',
                dataIndex: 'hostname',
                key: 'hostname',
            },
            {
                title: '开始时间',
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title: '权重',
                dataIndex: 'weight',
                key: 'weight',
            },
        ]

        return (

            <>
                <p style={{textAlign: "center"}} className={"footer2"}>

                    <CustomBtnModal
                        buttonStyle={{
                            display: "inline",
                            width: "auto",
                        }}
                        btnText="加入推荐"
                        title="加入推荐">
                        {/*    生成一个table*/}

                        <h4>当前推荐网站</h4>
                        <Table
                            columns={columns}
                            dataSource={this.state.sponsorInfo}
                            pagination={false}
                        />

                        <h4>加入推荐列表</h4>
                        <Button type="link"
                                onClick={openNewTab("https://docs.qq.com/doc/DT0xOS2JCUlhRTXdw")}>
                            <span style={{color: 'red'}}>点我：加入推荐列表</span>
                        </Button>
                    </CustomBtnModal>
                    <Button type="link" onClick={openNewTab("https://github.com/xx025/carrot")}>GitHub 仓库</Button>
                    <Button type="link" onClick={openNewTab("https://openai.com/")}>OpenAI官网</Button>
                    <Button type="link" onClick={openNewTab("https://llama.meta.com/")}>Meta LLama官网</Button>
                    <Button type="link" onClick={openNewTab("https://huggingface.co/")}>抱脸网</Button>
                    <Button type="link" onClick={openNewTab("https://modelscope.cn/home")}>魔搭社区</Button>

                </p>

                <div className="footer-container" style={{
                    paddingTop: "10px",
                    paddingBottom: "10px"
                }}>
                    <p>本站仅提供网址导航服务，所指向的链接均为第三方网站，对其内容不负任何责任，请谨慎访问。</p>
                    <p style={{display: "inline"}}><span>Copyright © 2023 ai55.cc All rights reserved.</span>
                    </p>
                </div>
            </>

        );
    }
}

export default Footer;