import React, {Component} from 'react';
import {Typography} from 'antd';

class WeChat extends Component {

    render() {

        const layoutStyle = {
            borderRadius: 8,
            overflow: 'hidden',
            display: 'block',
            backgroundColor: 'rgba(38,29,29,0.97)',
            bottom: 0,
            width: '100%',
            height: '100%',
        };

        const {Paragraph} = Typography;

        const url = window.location.href;

        return (
            <div style={layoutStyle}>
                <img src="/imgs/20201216150438676.png" alt="wechat" style={{width: '100%'}}/>
                <div style={{
                    backgroundColor: "#efebeb",
                    margin:"0 20px",
                    padding:"20px",
                    fontSize:"24px",
                }}>
                    <p>网站不能正常显示？</p>
                    &nbsp;
                    <p style={{fontSize:"20px"}}>
                        请点击右上角-在默认浏览器中打开，或者复制以下网址在外部浏览器打开
                    </p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Paragraph strong copyable={{text: url}} >{url}</Paragraph>
                </div>
            </div>
        );
    }
}

export default WeChat;