import React, {Fragment, useState} from 'react';
import {nanoid} from "nanoid";
import {SiteCard} from "../SiteCard";
import APIAllSite from "../../../api/sites.json";

function Index() {
    const [allSites] = useState(APIAllSite);


    return (
        <Fragment>
            {allSites.map((item) => (
                <Fragment key={nanoid()}>
                    <h2>{item.type_name}</h2>
                    <div className="apps-container clearfix">
                        {item.sites.map((site) => (
                            <SiteCard key={nanoid()} {...site} />
                        ))}
                    </div>
                </Fragment>
            ))}
        </Fragment>
    );
}

export default Index;
