//在新窗口中打开链接
export function openNewTab(url) {
    return () => {
        window.open(url, '_blank'); // 在新窗口中打开链接
    }
}

//判断是否是微信浏览器
export function isWeChat() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.includes('micromessenger')
}


//  获取访问次数
export function getVisitCount(key = 'visitCount') {
    // 获取存在localStorage中已有的访问次数
    let count = localStorage.getItem(key);

    if (count === null) {
        count = 0;
    }
    count = parseInt(count);
    count += 1;
    localStorage.setItem(key, count.toString());
    return count;
}


export function getHostName(urlString) {
    let url = new URL(urlString);
    return url.hostname;
}