import React, {Component} from 'react';
import {Alert} from 'antd';

class Neck extends Component {
    render() {
        const message = `
                🆕: 最近更新 ;
                ⭐: 使用稳定，推荐;
                😄: 免费使用 ;
                🔑: 需要登录或密码 ;
                ✈️: 需国际网络进行访问;
                `

        return (
            <Alert message={message} type="success" style={{
                backgroundColor:"white",
                border:"1px solid #0000001c",
                marginBottom:"10px",
                marginTop:"10px"
            }}/>
        );
    }
}

export default Neck;