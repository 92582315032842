import React from 'react';
import { Modal } from 'antd';
import {getVisitCount} from "../../utils";

class PromptToFollowWechatModal extends React.Component {
    state = {
        visible: false,
        secondsLeft: 30,
    };

    countdownTimer = null;
    showModalTimer = null;

    componentDidMount() {
        if (getVisitCount()===1) {
            this.showModal(); // 用户首次访问时，显示模态框
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });

        // 10秒后自动关闭
        this.showModalTimer = setTimeout(() => {
            this.setState({
                visible: false,
            });
            if (this.countdownTimer) {
                clearInterval(this.countdownTimer); // 清除定时器
            }
        }, 30000);

        // 设置倒计时定时器
        this.countdownTimer = setInterval(() => {
            this.setState(prevState => ({
                secondsLeft: prevState.secondsLeft - 1,
            }));
        }, 1000);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer); // 清除定时器
        }
    };

    componentWillUnmount() {
        if (this.countdownTimer) {
            clearInterval(this.countdownTimer); // 清除定时器
        }
        if (this.showModalTimer) {
            clearTimeout(this.showModalTimer); // 清除定时器
        }
    }

    render() {


        const style = {
            color: "#f82a2a",
            fontSize: "16px",
        }


        return (
            <div>
                <Modal
                    title="关注我们的微信公众号"
                    open={this.state.visible}
                    footer={null}
                    onCancel={this.handleCancel}

                >
                    <p>关注我们的微信公众号，了解最新活动信息！</p>
                    <img src="/imgs/gzh.png" alt={"微信公众号"} style={{maxWidth: "100%"}}/>
                    <p style={style}>扫码关注公众号，永不走失</p>
                    <p style={style}>网站可能意外不可访问，如果网站不可访问，我们会在公众号内发布最新的网址</p>
                    <p>窗口将在 {this.state.secondsLeft} 秒后自动关闭</p>
                </Modal>
            </div>
        );
    }
}

export default PromptToFollowWechatModal;