import React, {Component} from "react";
import {Link} from "@material-ui/core";
import {nanoid} from "nanoid";
import {Avatar, Popover} from "antd";

const siteCardStyle = {
    textDecoration: 'none',
}

export class SiteCard extends Component {


    onCLick = () => {
        const url = this.props.url;
        window.open(url, '_blank', 'resizable=no')
    }

    render() {
        let {tag, text, icon, title} = this.props;
        // console.log(this.props, tag, text, icon, title)
        return (
            <div className="app-container" data-id="evernote">
                <Popover content={
                    <div style={{maxWidth: "300px"}}>
                        {text}
                    </div>
                } title={ title + " " + (tag ? tag : "")} placement="bottom">
                    <Link className="app-item"
                          key={nanoid()}
                          rel="noopener"
                          target="_blank"
                        // title={tag+text+title}
                          onClick={this.onCLick}
                          style={siteCardStyle}
                    >
                        <div className="app-icon">
                            <Avatar shape="square" size={68} src={icon}/>
                        </div>
                        <div className="app-text">
                            <p className="app-title">{title}</p>
                            <p className="app-desc">{
                                `${tag === undefined ? "" : (tag ? tag : "")}
                            ${text ? text : ""}`}</p>
                        </div>
                    </Link>
                </Popover>

            </div>
        );
    }
}