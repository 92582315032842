import React, {Fragment} from 'react';


import Main from "./Main";
import Sponsor from "./Sponsor";
import Neck from "../Neck";


function SiteContent() {


    return (
        <>    <Neck/>
            <Sponsor/>

            <Main/>
        </>
    );
}

export default SiteContent;