import Footer from "./components/Footer";
import Header from "./components/Header";
import {Divider} from "antd";
import SiteContent from "./components/Content";
import {isWeChat} from "./utils";
import {Component} from "react";
import WeChat from "./pages/WeChat";
import PromptToFollowWechatModal from "./components/WeChat";


class App extends Component {

    render() {
        if (isWeChat()) {
            return <WeChat/>
        }
        return (
            <>
                <div className="container no-select">
                    <div className="plugin-container" id="container-apps">
                        <div className="module-container" id="hero-container">
                            <Header/>
                        </div>
                        <Divider plain style={{margin: "4px 0"}}/>

                        {/*<Divider plain style={{margin:"4px 0"}}/>*/}
                        {/*<Divider plain style={{margin:"4px 0"}}/>*/}
                        <SiteContent/>
                        <Divider plain style={{margin: "4px 0"}}/>
                        <Footer/>
                    </div>
                </div>
                <PromptToFollowWechatModal/>

            </>
        )
    }
}

export default App;
