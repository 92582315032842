import React, {Component} from 'react';
import BtnLine from "./BtnLine";


class Header extends Component {
    render() {
        return (
            <div id="plugin-datetime">
                <h1 id="plugin-greetings">Free ChatGPT Site List</h1>
                <p>这儿为你准备了众多免费好用的ChatGPT镜像站点 </p>
                <BtnLine/>
            </div>
        );
    }
}

export default Header;