import React, {Fragment, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import {SiteCard} from "../SiteCard";
import {nanoid} from "nanoid";
import SpData from "../../../api/sp.json";
import {NotificationOutlined} from '@ant-design/icons';


const now = new Date();
// let validSponsors = SpData.filter(item => {
//     const valid = item.valid ?? true; // 一般不填写
//     return new Date(item.endTime) > now && valid;
// });


// 处理数据
let validSponsors = SpData.map(item => {
    const valid = item.valid ?? true;
    if ((new Date(item.endTime) <= now) || !valid) {
        // 我们复制了item对象，但改变了title属性
        return {...item,
            title:"您的网站",
            text:"空缺,点击加入",
            url:"https://docs.qq.com/doc/DT0xOS2JCUlhRTXdw",
            icon:"https://st2.ai55.cc/1/28de27d134b135f11a6af38b50e63a4b.gif"
        };
    }else{
        return item;
    }
});


class SponsorCard extends SiteCard {
    onCLick = () => {
        const url = this.props.url;
        window.open(url, '_blank',)
    }
}


function Index() {

    const [spData] = useState(validSponsors);
    return (
        <Fragment key={nanoid()}>
            <h2>推荐</h2>
            <p style={{
                color: "rgba(3,89,255,0.94)",
            }}><NotificationOutlined style={{fontSize: 16}}/> 推荐网站可能包含付费内容服务，可选择性访问和使用</p>

            <div className="apps-container clearfix">
                <div className="apps-container clearfix">
                    {spData.map((item) => (
                        <SponsorCard key={uuidv4()} {...item}/>
                    ))}
                </div>
            </div>


        </Fragment>

    );
}

export default Index;