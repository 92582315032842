// 引入 React 核心库
import React from 'react'
// 引入 ReactDOM
// import ReactDOM from 'react-dom'

// 引入 App 组件
import App from './App'

// 渲染 App 组件到页面
// ReactDOM.render(<App />, document.getElementById('root'))


import {createRoot} from 'react-dom/client';

const container = document.getElementById('page-home');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <App tab="home"/>
);