import React, {useState} from 'react';
import {Button, Divider, Flex} from "antd";
import {PlusSquareOutlined, SolutionOutlined, WechatOutlined} from "@ant-design/icons";
import {openNewTab} from "../../../utils";


import {Modal} from 'antd';


export const CustomBtnModal = ({buttonStyle, icon, btnText, title, children}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button
                type="link"
                block
                style={buttonStyle}
                icon={icon}
                onClick={showModal}
            >
                {btnText}
            </Button>
            <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                {children}
            </Modal>
        </>
    );
};
const btnCustom = {
    color: "#000",
    width: "auto",
    maxWidth: "30%",
}


const BtnLine = () => {


    const addSiteLink = "https://github.com/xx025/carrot/issues/new?assignees=&labels=add-site&projects=&template=addSet.md&title=%E6%B7%BB%E5%8A%A0%E7%AB%99%E7%82%B9"

    const feedBackLink = "https://github.com/xx025/carrot/issues/new?assignees=&labels=&projects=&template=feedback.md&title=%5B%E5%8F%8D%E9%A6%88%5D+"
    return (
        <Flex gap="small" wrap="wrap" style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: "6px"
        }}>
            <Button type="link" block
                    icon={<PlusSquareOutlined/>}
                    style={btnCustom}
                    onClick={openNewTab(addSiteLink)}
            >
                添加站点
            </Button>


            <CustomBtnModal
                buttonStyle={btnCustom}
                icon={<SolutionOutlined/>}
                btnText="反馈"
                title="反馈">
                <img src="/imgs/gzh.png" alt={"微信公众号"} style={{maxWidth: "100%"}}/>
                <p style={{textAlign: 'center', fontSize: '16px'}}>请关注公众号发送你要反馈的内容</p>

                <Divider plain/>

                <Button block style={{...btnCustom, left: '40%'}} onClick={openNewTab(feedBackLink)}>GitHub
                    issue</Button>
                <p style={{textAlign: 'center', fontSize: '16px'}}>或者直接在GitHub上提交issue</p>

            </CustomBtnModal>

            <CustomBtnModal
                buttonStyle={btnCustom}
                icon={<WechatOutlined/>}
                btnText="公众号"
                title="公众号">
                <img src="/imgs/gzh.png" alt={"微信公众号"} style={{maxWidth: "100%"}}/>
                <p style={{textAlign: 'center'}}>关注公众号，永不走失</p>
                <p style={{textAlign: 'center'}}>如果网站不可访问，我们会在公众号内发布最新的网址</p>
            </CustomBtnModal>


        </Flex>
    );

}

export default BtnLine;